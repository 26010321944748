<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('produit.produits') }}</h2>
    </div>

    <el-card class="box-card">
      <div id="printMe" class="content-inputs">
        <h4>
          <!-- {{ detailDataM !== null? 'Détails du produit : '+detailDataM.nom: 'Détails' }} -->
          Détails
        </h4>
        <el-table id="forprint" :data="data" :show-header="false">
          <el-table-column prop="name" label="Name" />
          <el-table-column label="Val" align="left">
            <template slot-scope="scope">
              {{ scope.row.val }}
            </template>
          </el-table-column>
        </el-table>
        <span v-if="printing !== true" slot="footer" class="dialog-footer">
          <div style="text-align:right;margin-top:'auto'">
            <!-- <el-button plain type="info" @click="goBack">
              {{ $t('permission.cancel') }}
            </el-button> -->
            <el-button plain type="primary" @click="printDiv('printMe')">
              Impression via SCIAGRI_
            </el-button>
          </div>
        </span>
      </div>
    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
const produitResource = new Resource('produits');
export default {
  name: 'ProduitsUnique',
  props: {
    produit: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: null,
      printing: false,
      list: null,
      mininfos: [
        {
          'name': 'Ble',
          'val': 'desc ble',
        },
        {
          'name': 'Soja',
          'val': 'desc soja',
        },
      ],
    };
  },
  computed: {

  },
  created() {
    this.list = this.produit;
    this.getProduit();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    showDetails(row) {
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    goBack(){
      // this.$router.go(-1);
    },
    async getProduit() {
      const { data } = await produitResource.get(this.$route.params.id);
      this.data = [
        {
          'name': 'Code',
          'val': data.code,
        },
        {
          'name': 'Description',
          'val': data.description,
        },
      ];
      console.log('leproduitttttttttt : ', data);
      // if (data !== null) {
      //   window.print();
      // }
    },
    printDiv(divName){
      this.printing = true;
      var printContents = document.getElementById(divName).innerHTML;
      // var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      // document.body.innerHTML = originalContents;
      this.printing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
